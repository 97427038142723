<template>
  <div class="certCenter_bg" style="min-height: 100vh;background-color: #171726;overflow: scroll;">
        
        <van-loading size="24px" v-if="loading" style="text-align:center;" class="margin-top">加载中...</van-loading>
        <div v-else>
          <div class="cash_title" style="background:none;">
            <img class="cash_back" src="img/backw.png" alt="" @click="closePage">
            <span style="color:#ffffff">大神中心</span>
        </div>
          <div class="cert_userinfo display_flex align-items_center">
              <img class="user_logo" :src="user_info.pic" alt="">
              <div class="margin-left-sm" style="display: flex;align-items: center;">
                  <span style="color:#ffffff;font-size: 16px;">{{user_info.nickname}}</span>
                  <img class="renz_status" src="img/game/cert_renz.png" alt="">
              </div>
          </div>
          <div class="certUser_box" style="background-image: url(img/game/cert_box.png);">
            <span>我的收益(元)</span>
            <p>{{user_info.money}}</p>
            <div class="cert_goCash" style="background-image: url(img/game/cert_goCash.png);" @click="goCash"></div>
          </div>
          <div class="master_box padding-top">
            <div class="certList_tit" style="margin-left:20px;">
                <span>我的技能</span>
            </div>
            <div class="padding-lr">
                <div class="master-item display_flex justify-content_flex-justify align-items_center" v-for="(item,index) in game_skill" :key="index">
                    <div class="display_flex align-items_center">
                        <img :src="item.game_logo" alt="">
                        <p style="color:#ffffff">{{item.game_name}}</p>
                    </div>
                    <div class="display_flex align-items_center">
                        <div class="master_edit" style="background-image: url(img/game/go_edit.png);" @click="goCertGame(item.game_auth_id)"></div>
                        <van-switch active-color="#fff87c" inactive-color="#F3F3F4" v-model="item.checked" @change="changeChecked(item)" />
                    </div>
                </div>
            </div>
            <div class="master_add" style="background-image: url(img/game/addMaster.png);" @click="goCert"></div>
            
          </div>

        </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/game.css"
import NavBar from '@/components/navBar.vue';
import {masterCentre,controlReceiveOrder} from "@/api/api";
export default {
  name: 'certCenter',
  components: {
    NavBar
  },
  data() {
    return {
      loading:true,
      user_id:'',
      user_info:{},
      game_skill:[],
      checked:false
    };
  },
  created() {
    let access_token = this.$route.query.access_token
    this.user_id = this.$route.query.user_id
    localStorage.setItem('access_token', access_token)
    this.fetchData()
  },
  methods: {
    fetchData(){
        masterCentre({user_id:this.user_id}).then(response => {
            this.loading = false
            this.user_info = response.data.user_info
            var newArr = response.data.game_skill.map(v => {
              if(v.is_open == 1){
                return {...v,checked:true}
              }else{
                return {...v,checked:false}
              }
              
            })
            this.game_skill = newArr

        },
        error => {
            this.$toast.fail(error)
        }).catch((error) => {
            this.$toast.fail(error)
        })
				
    },
    changeChecked(item){
      console.log(item)
      let open_status = item.checked ? 1: 0
      controlReceiveOrder({game_auth_id:item.game_auth_id,open_state:open_status}).then(response => {
        this.$toast(response.code==10000?'操作成功~':'操作失败~')
        this.fetchData()
      },
      error => {
          this.$toast.fail(error)
      }).catch((error) => {
          this.$toast.fail(error)
      })
    },
    goCertGame(id){
      let str = {
        auth_id:id
      }
      let params = JSON.stringify(str)
      this.$bridge.callhandler('certEdit', params, (data) => {
      // 处理返回数据
      })
    },
    goCert(){
        this.$router.push({path:'/gameList'})
    },
    goCash(){
        this.$router.push({path:'/gameCash'})
    },
    closePage(){
        let params = '调用'
        this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
        })
    },

  },
};
</script>
<style>
.van-switch{
    border:none;
}
</style>
